<template>
  <div class="tariffs-table">
    <!--      v-if="!!tags.length "-->
    <tags-filter
      :tags="tags"
      :search="search"
      search-type="server"
      :selected-tags="selectedTags"
      @reset="resetTag()"
      @search-server="searchServer($event)"
      @search-type="searchType($event)"
      @click-tag="clickTag($event)"
    ></tags-filter>
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-server-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :filtred-tags="selectedTags"
        :filtred-search="searchFilter"
        :filtred-type="searchByType"
        :item="item"
        @click-tag="clickTag($event)"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableServerRow from './TariffsTableServerRow';
import NewPagination from '@/components/Pagination/NewPagination';
import TagsFilter from '@/layouts/Stack/components/TagsFilter';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableServer',
  components: {
    TagsFilter,
    BaseTable,
    TariffsTableServerRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      searchByType: 'name',
      selectedTags: [],
      searchFilter: '',
      tableHead: [
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            minWidth: '100px',
            maxWidth: '150px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'os',
          label: this.$t('tableHead.os'),
          style: {
            minWidth: '100px',
            maxWidth: '150px',
          },
          // sort: {
          //   prop: 'os',
          //   order: 'asc',
          // },
        },
        {
          key: 'config',
          label: this.$t('tableHead.config'),
          style: {
            minWidth: '140px',
            maxWidth: '180px',
          },
        },
        {
          key: 'ip',
          label: this.$t('tableHead.ip'),
          style: {
            minWidth: '100px',
            maxWidth: '150px',
          },
        },
        {
          key: 'created',
          label: this.$t('tableHead.created'),
          style: {
            minWidth: '40px',
            maxWidth: '60px',
          },
          sort: {
            prop: 'created',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            minWidth: '34px',
            maxWidth: '54px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            minWidth: '34px',
            maxWidth: '45px',
          },
        },
      ],
      checkedList: [],
      checkedAll: false,
    };
  },
  computed: {
    list() {
      const list = this.$store.getters['moduleStack/GET_SORTED_LIST_SERVERS'];

      if (this.$route.query.value) {
        if (this.searchByType === 'name')
          return list.filter(x =>
            x.name.toLowerCase().includes(this.$route.query.value.toLowerCase())
          );
        else {
          return list.filter(x => x.port.find(s => s.includes(this.$route.query.value)));
        }
      }
      if (!!this.selectedTags.length) {
        const tags = this.selectedTags;
        const tagsServer = list.map(x => x.tags);

        let intersection = (first, second) => {
          let s = new Set(second);
          const filtredArray = [];
          first.forEach((x, number) => {
            if (x.filter(i => s.has(i)).length === tags.length) {
              filtredArray.push(list[number].id);
            }
          });
          return filtredArray;
        };
        let common = intersection(tagsServer, tags);
        return list.filter(x => common.includes(x.id));
      } else return list;
    },
    sort() {
      return this.$store.state.moduleStack.sort.server;
    },
    tags() {
      return this.$store.getters['moduleStack/getTags'];
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
    '$route.query.value': {
      handler: function (val) {
        this.searchFilter = val;
        this.search = val;
      },
      immediate: true,
    },
    searchFilter: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
    this.search = '';
    this.setFilter(this.$route.query.value);
  },
  methods: {
    setFilter(val) {
      this.searchFilter = val;
      this.search = val;
      this.$router
        .push({
          name: 'containerServer',
          query: { filter_by: 'name', value: val },
        })
        .catch(() => {});
    },
    clickTag(event) {
      if (this.selectedTags.includes(event)) {
        this.selectedTags = this.selectedTags.filter(x => x !== event);
      } else {
        this.selectedTags.push(event);
      }
    },
    searchServer(event) {
      this.searchFilter = event;
    },
    searchType(event) {
      this.searchByType = event;
    },
    resetTag() {
      this.selectedTags = [];
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_SERVER_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "server": "Имя",
      "os": "ОС",
      "ip": "IP",
      "active": "Активен",
      "build": "Создаётся",
      "error": "Создан с ошибкой",
      "config": "Конфигурация",
      "created": "Создан",
      "status": "Статус",
      "task": "Задача",
      "cost": "Цена",
      "menu": "Меню"
    },
    "reset": "Сбросить все теги",
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tags {
    margin: 1.25rem 0;
    display flex
    align-items: center;
    flex-wrap: wrap;

    &-item {
      margin: 0.5rem 0 0.5rem 0.5rem

    }
    &-reset {
      margin: 0.5rem 0 0.5rem 0.5rem
    }
  }

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
